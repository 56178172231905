import { TTLinkObject, TT_LINK_DEFAULT_VERSION, TT_LINK_LABEL_PREFIX } from '@netinsight/crds';
import { PersistedSyncLink, UnresolvedSyncLink } from './time-transfer';

export function toSyncLink(ttLink: TTLinkObject): PersistedSyncLink {
  return {
    id: ttLink.metadata!.name!,
    name: ttLink.spec.name,
    endpointA: ttLink.spec.endpointA,
    endpointB: ttLink.spec.endpointB,
    profiles: ttLink.spec.profiles ?? [],
    autoCalibration: ttLink.spec.autoCalibration,
    timestampRate: ttLink.spec.timestampRate,
    timestampRateDivisor: ttLink.spec.timestampRateDivisor,
    packetSize: ttLink.spec.packetSize,
    options: ttLink.spec.options,
  };
}

export function toTTLinkObject(id: string, namespace: string, link: UnresolvedSyncLink): TTLinkObject {
  return {
    apiVersion: `netinsight.net/${TT_LINK_DEFAULT_VERSION}`,
    kind: 'TTLink',
    metadata: {
      name: id,
      namespace: namespace,
      labels: {
        [`${TT_LINK_LABEL_PREFIX}0`]: link.endpointA.node,
        [`${TT_LINK_LABEL_PREFIX}1`]: link.endpointB.node,
      },
    },
    spec: {
      name: link.name,
      endpointA: link.endpointA,
      endpointB: link.endpointB,
      profiles: link.profiles ?? [],
      autoCalibration: link.autoCalibration,
      timestampRate: link.timestampRate,
      timestampRateDivisor: link.timestampRateDivisor,
      packetSize: link.packetSize,
      options: link.options,
    },
  };
}
