import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SSHKeysConfigForm } from '../SSHKeysConfigForm';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { InfoCard, Progress, ResponseErrorPanel } from '@backstage/core-components';
import { useFormStyles, useGlobalSSHConfig, useGlobalSSHConfigUpdate } from '@netinsight/management-app-common-react';
import { GlobalSSHConfig } from '@netinsight/management-app-common-api';
import Alert from '@mui/material/Alert';

export const GlobalSSHConfigCard = () => {
  const { data: sshKeyConfig, isLoading, error } = useGlobalSSHConfig();
  const { trigger: updateGlobalSSHConfig } = useGlobalSSHConfigUpdate();

  const defaultValues = useMemo(() => {
    return sshKeyConfig;
  }, [sshKeyConfig]);

  const formMethods = useForm<GlobalSSHConfig>({
    mode: 'onChange',
    defaultValues,
  });

  const {
    reset: resetForm,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty },
  } = formMethods;

  const submitNewSshConfig = useCallback(
    async (newSshKeyConfig: any) => {
      return updateGlobalSSHConfig(newSshKeyConfig);
    },
    [updateGlobalSSHConfig],
  );

  // Make sure default values are set correctly
  useEffect(() => {
    resetForm(defaultValues);
  }, [resetForm, defaultValues]);

  const styles = useFormStyles();

  if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  if (isLoading) {
    return <Progress />;
  }

  return (
    <InfoCard title="Global SSH Configuration">
      <Alert severity="info">
        <Typography variant="body2">SSH keys managed here are applied to all nodes.</Typography>
      </Alert>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitNewSshConfig)} noValidate>
          <SSHKeysConfigForm />
          <Box marginTop={2} className={styles.buttonContainer}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!isValid || isSubmitting}
              data-testid="btn-submit-authentication-form"
              startIcon={isSubmitting ? <CircularProgress size="1.5rem" /> : null}
            >
              Apply
            </Button>
            <Button
              color="default"
              variant="outlined"
              disabled={!isDirty}
              onClick={() => resetForm(defaultValues)}
              data-testid="btn-cancel-authentication-form"
            >
              Reset
            </Button>
          </Box>
        </form>
      </FormProvider>
    </InfoCard>
  );
};
