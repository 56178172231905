import { useMemo } from 'react';
import { merge } from 'ts-deepmerge';
import { G } from '@mobily/ts-belt';
import { TT_LINK_DEFAULT_PORT } from '@netinsight/crds';
import {
  isNullableOrEmpty,
  useNodeLinkTargets,
  useNodeManagerConfig,
  useTimeTransferConfig,
} from '@netinsight/management-app-common-react';
import { formatInterfaceName, mapInterfaces } from '../helpers';
import { InterfaceUsage } from '../types';

export const useInterfaceUsage = (nodeId: string) => {
  const {
    config: nodeManagerConfig,
    loading: isLoadingNodeManagerConfig,
    error: nodeManagerConfigError,
  } = useNodeManagerConfig(nodeId);
  const {
    data: timeTransferConfig,
    isLoading: isLoadingTimeTransferConfig,
    error: timeTransferConfigError,
  } = useTimeTransferConfig(nodeId);
  const { data: linkTargets, isLoading: isLoadingLinkTargets, error: linkTargetsError } = useNodeLinkTargets(nodeId);

  const result: Record<string, InterfaceUsage> = useMemo(() => {
    const linkUsage = Object.fromEntries(
      (linkTargets ?? []).map(lt => [
        formatInterfaceName(lt.iface, lt.vlanId),
        { links: { [lt.linkId]: lt.port ?? TT_LINK_DEFAULT_PORT } },
      ]),
    );
    const synceUsage = G.isNotNullable(nodeManagerConfig)
      ? Object.fromEntries(
          mapInterfaces(nodeManagerConfig, (iface, vlanIface) => [
            formatInterfaceName(iface.name, vlanIface?.id),
            {
              syncE: G.isNotNullable(vlanIface) ? vlanIface.useSynce : iface.useSynce,
            },
          ]),
        )
      : {};
    const ptpTransmitterUsage = G.isNotNullable(timeTransferConfig)
      ? Object.fromEntries(
          timeTransferConfig.ptpGm?.instances?.map(ptpGmInstance => [
            ptpGmInstance.interface,
            { ptpTransmitter: ptpGmInstance.profile },
          ]) ?? [],
        )
      : {};
    const ptpReceiverUsage = G.isNotNullable(timeTransferConfig)
      ? Object.fromEntries(
          timeTransferConfig.ptpClients?.instances
            ?.filter(client => !isNullableOrEmpty(client.interface))
            .map(client => [client.interface!, { ptpReceiver: client.profile }]) ?? [],
        )
      : {};
    return merge(linkUsage, synceUsage, ptpTransmitterUsage, ptpReceiverUsage);
  }, [linkTargets, timeTransferConfig, nodeManagerConfig]);
  return {
    data: result,
    isLoading: isLoadingLinkTargets || isLoadingNodeManagerConfig || isLoadingTimeTransferConfig,
    error: nodeManagerConfigError ?? timeTransferConfigError ?? linkTargetsError,
  };
};
