import { useMemo } from 'react';
import { groupMetricsVector, useInstantMetrics } from '@netinsight/management-app-common-react';

export const useLinkSelectedProfileIndices = (linkId: string) => {
  const { metrics: selectedProfileIndexMetrics } = useInstantMetrics({
    query: `neti_hicc_multilink_selected_profile{link_id="${linkId}"}`,
    options: { refreshInterval: 7_500 },
  });
  const selectedProfileIndices = useMemo(
    () =>
      groupMetricsVector(
        selectedProfileIndexMetrics,
        metric => metric.nodeid,
        val => parseInt(val, 10),
      ),
    [selectedProfileIndexMetrics],
  );
  return selectedProfileIndices;
};
