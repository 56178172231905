import { TTLinkProfile } from '@netinsight/crds';

export const getDelayToAndFromRemote = ({
  roundtripTime,
  delayDifference,
}: Pick<TTLinkProfile, 'roundtripTime' | 'delayDifference'>): Pick<
  TTLinkProfile,
  'delayFromRemote' | 'delayToRemote'
> => {
  return {
    delayToRemote: roundtripTime / 2 - delayDifference / 2,
    delayFromRemote: roundtripTime / 2 + delayDifference / 2,
  };
};

export const getRoundTripTimeAndDelayDifference = ({
  delayToRemote,
  delayFromRemote,
}: Pick<TTLinkProfile, 'delayToRemote' | 'delayFromRemote'>): Pick<
  TTLinkProfile,
  'roundtripTime' | 'delayDifference'
> => {
  return {
    roundtripTime: delayFromRemote + delayToRemote,
    delayDifference: delayFromRemote - delayToRemote,
  };
};

export const equalValues = (x: number, y: number, tolerance = 5.5e-4) => Math.abs(x - y) < tolerance;
