import React from 'react';
import { merge as deepMerge } from 'ts-deepmerge';
import { G, S, A } from '@mobily/ts-belt';
import { TimeTransferSpec } from '@netinsight/crds';
import {
  AdminStatus,
  Configuration,
  EthMacSpeed,
  NodeState,
  PhysicalInterfaceDefinition,
  RouteDefinition,
  VlanInterfaceDefinition,
} from '@netinsight/node-manager-schema';
import { NodeManagerConfig } from '@netinsight/management-app-common-api';
import type {
  InterfaceInfo,
  InterfaceStates,
  NetworkInterfaceStateWithVlan,
  NetworkNamespace,
  NetworkNamespaceConfig,
  RoutingRule,
} from './types';
import { DEFAULT_NAMESPACES, DEFAULT_NAMESPACE_LABELS } from './constants';

type NetworkInterfaceConfig = Configuration['interfaces'][number];

const normalizeNamespace = (ns: string) =>
  G.isNullable(ns) || ns === DEFAULT_NAMESPACES.management ? DEFAULT_NAMESPACES.default : ns;

const formatVlan = (ifaceName: string, vlanId: number) => `${ifaceName}.${vlanId}`;

export const formatInterfaceName = (ifaceName: string, vlanId?: number) =>
  G.isNotNullable(vlanId) ? formatVlan(ifaceName, vlanId) : ifaceName;

export const reconcileInterfaceConfigStates = (
  interfaceConfigs?: NetworkInterfaceConfig[],
  sfpStates?: NodeState.Transceiver[],
): NetworkInterfaceConfig[] => {
  if (G.isNullable(sfpStates) || sfpStates.length === 0) {
    return interfaceConfigs ?? [];
  }
  const interfaceConfigsMap = Object.fromEntries(
    interfaceConfigs?.map(ifaceConfig => [
      ifaceConfig.name,
      { ...ifaceConfig, vlanInterfaces: ifaceConfig.vlanInterfaces ?? [] },
    ]) ?? [],
  );
  return sfpStates
    .filter(xcvr => G.isNotNullable(xcvr.interfaceName) && S.isNotEmpty(xcvr.interfaceName))
    .map(
      xcvr =>
        interfaceConfigsMap[xcvr.interfaceName] ?? {
          name: xcvr.interfaceName,
          vlanInterfaces: [],
          ipAddress: undefined,
          networkNamespace: DEFAULT_NAMESPACES.default,
          adminStatus: AdminStatus.Down,
          linkSpeed: EthMacSpeed.Speed10G,
        },
    );
};

export const getInterfaceStateMap = (
  state?: NodeState.NodeState,
  timeTransferConfig?: TimeTransferSpec,
): Record<string, InterfaceStates> => {
  const states = Object.fromEntries(
    state?.interfaces?.map(is => [
      is.name,
      {
        state: {
          ...is,
          vlanStates: Object.fromEntries(
            state?.vlanInterfaces?.filter(vlan => vlan.parentInterfaceUid === is.uid)?.map(vlan => [vlan.uid, vlan]) ??
              [],
          ),
        } as NetworkInterfaceStateWithVlan,
      },
    ]) ?? [],
  );
  const xcvrStates = Object.fromEntries(
    state?.transceivers?.map(is => [is.interfaceName, { sfpStates: is as NodeState.SfpTransceiver }]) ?? [],
  );
  const timeTransferConfigs = Object.fromEntries(
    timeTransferConfig?.ptpGm?.instances?.map(ptpGm => [
      ptpGm.interface,
      { timeTransferConfig: { ptp: true as boolean } },
    ]) ?? [],
  );
  return deepMerge(states, xcvrStates, timeTransferConfigs);
};

export const getRouteStateByNamespaces = (state?: NodeState.NodeState) =>
  A.groupBy(state?.routes?.routes ?? [], r => r.networkNamespace ?? DEFAULT_NAMESPACES.default) as Record<
    string,
    ReadonlyArray<NodeState.RouteInfo>
  >;

export const getDefaultNamespaceOptions = () =>
  React.createElement(
    React.Fragment,
    {},
    [DEFAULT_NAMESPACES.default, DEFAULT_NAMESPACES.access].map(ns =>
      React.createElement('option', { key: ns, value: ns }, DEFAULT_NAMESPACE_LABELS[ns]),
    ),
  );

export const getNamespaceConfig = (
  interfaceInfos: InterfaceInfo[],
  nodeManagerConfig?: NodeManagerConfig,
): NetworkNamespaceConfig => {
  const interfaceByNamespaces = A.groupBy(interfaceInfos, iface => normalizeNamespace(iface.networkNamespace));
  const allNamespaces = [
    ...new Set<string>(
      interfaceInfos
        .map(iface => normalizeNamespace(iface.networkNamespace))
        .concat(nodeManagerConfig?.namespaces?.map(normalizeNamespace) ?? [])
        .concat([DEFAULT_NAMESPACES.default, DEFAULT_NAMESPACES.access]),
    ).values(),
  ].sort((ns1, ns2) => ns1.localeCompare(ns2));
  const routingConfigsByNamespace = A.groupBy(nodeManagerConfig?.routes ?? [], r =>
    normalizeNamespace(r.networkNamespace),
  );

  return {
    networks: allNamespaces.map<NetworkNamespace>(ns => ({
      name: ns,
      displayName: DEFAULT_NAMESPACE_LABELS[ns] ?? ns,
      interfaces: interfaceByNamespaces[ns]?.map(iface => iface.id) ?? [],
      routes:
        routingConfigsByNamespace[ns]?.map<RoutingRule>(({ network, gateway }) => ({
          network,
          gateway,
        })) ?? [],
    })),
  };
};

export function formatNodeManagerConfig(config: Configuration): Configuration {
  return {
    ...config,
    interfaces: config.interfaces.map(iface => {
      const hasIpAddress = G.isString(iface.ipAddress) && S.isNotEmpty(iface.ipAddress);
      const isNotUsingDHCP = iface.useDhcp !== true;
      return {
        ...iface,
        ipAddress: hasIpAddress && isNotUsingDHCP ? iface.ipAddress : undefined,
      };
    }),
  };
}

export function getNodeManagerConfig(
  config: Configuration,
  { networks: formValues }: NetworkNamespaceConfig,
): Configuration {
  const nsByIfaceNameMap = Object.fromEntries(
    formValues.flatMap(({ name: namespaceName, interfaces }) => interfaces.map(iface => [iface, namespaceName])),
  );
  return {
    ...config,
    interfaces: config.interfaces.map(iface => ({
      ...iface,
      networkNamespace: nsByIfaceNameMap[iface.name] ?? DEFAULT_NAMESPACES.default,
      vlanInterfaces: iface.vlanInterfaces?.map(vlan => ({
        ...vlan,
        networkNamespace: nsByIfaceNameMap[formatVlan(iface.name, vlan.id)] ?? DEFAULT_NAMESPACES.default,
      })),
    })),
    routes: formValues.flatMap(({ name, routes }) =>
      routes.map<RouteDefinition>(({ network, gateway }) => ({ network, gateway, networkNamespace: name })),
    ),
    namespaces: formValues.map(({ name }) => name).filter(n => n !== DEFAULT_NAMESPACES.default),
  };
}

export const mapInterfaces = <T>(
  nodeManagerConfig: NodeManagerConfig,
  mapper: (iface: PhysicalInterfaceDefinition, vlanIface?: VlanInterfaceDefinition) => T,
): T[] =>
  nodeManagerConfig?.interfaces?.flatMap(iface => [
    mapper(iface),
    ...(iface.vlanInterfaces?.map(vlanIface => mapper(iface, vlanIface)) ?? []),
  ]);
