import z from 'zod';

export const FieldErrorsSchema = z.record(
  z.object({
    message: z.string(),
    type: z.string(),
  }),
);

export type FieldErrors = z.infer<typeof FieldErrorsSchema>;

export const WrappedErrorSchema = z.object({
  error: z.object({
    name: z.string(),
    message: z.string(),
    details: FieldErrorsSchema.optional(),
  }),
  request: z.object({
    method: z.string(),
    url: z.string(),
  }),
  response: z.object({
    statusCode: z.number(),
  }),
});
export type WrappedError = z.infer<typeof WrappedErrorSchema>;

export const ServiceConfigSchema = z.object({});

export interface ServiceConfigResponse<T> {
  config: T;
}

export const JsonWebTokenSchema = z.object({
  // See https://www.oauth.com/oauth2-servers/access-tokens/access-token-response/
  access_token: z.string(),
  token_type: z.enum(['Bearer']),
});

export type JsonWebToken = z.infer<typeof JsonWebTokenSchema>;

export class ValidationError extends Error {
  constructor(public details: FieldErrors) {
    super();
  }
}

export const NODE_GRAPH_TOGGLES = {
  NodeLabels: 'node-labels',
  ParentNodes: 'parent-nodes',
} as const;

export const NODE_GRAPH_TOGGLES_SCHEMA = z.object({
  [NODE_GRAPH_TOGGLES.NodeLabels]: z.boolean().optional(),
  [NODE_GRAPH_TOGGLES.ParentNodes]: z.boolean().optional(),
});

export type NodeGraphToggles = z.infer<typeof NODE_GRAPH_TOGGLES_SCHEMA>;

export const NodeGraphHighlightSchema = z.union([
  z.literal('none').describe('None'),
  z.literal('alarms').describe('Alarms'),
  z.literal('control-states').describe('Control states'),
  z.literal('node-stability').describe('Node stability'),
  z.literal('ntp-selections').describe('NTP sever selections'),
  z.literal('sync-inputs').describe('Sync inputs'),
  z.literal('sync-outputs').describe('Sync outputs'),
  z.literal('references').describe('References'),
  z.literal('time-offsets').describe('Time offsets'),
]);

export type NodeGraphHighlight = z.infer<typeof NodeGraphHighlightSchema>;

export const NodeGraphStateSchema = z.object({
  name: z.string().optional(),
  width: z.number().optional(),
  height: z.number().optional(),
  toggles: NODE_GRAPH_TOGGLES_SCHEMA.optional(),
  backgroundImageUrl: z.string().optional(),
  syncRegions: z.array(z.string()).optional(),
  highlight: NodeGraphHighlightSchema.optional(),
  nodePositions: z
    .record(
      z.object({
        x: z.number(),
        y: z.number(),
      }),
    )
    .optional(),
  zoom: z.number().optional(),
  pan: z
    .object({
      x: z.number(),
      y: z.number(),
    })
    .optional(),
});

export type NodeGraphState = z.infer<typeof NodeGraphStateSchema>;
