import { Page, TabbedLayout } from '@backstage/core-components';
import React from 'react';
import { HeaderWithAlarms } from '../HeaderWithAlarms/HeaderWithAlarms';
import { GlobalLinkOptionsPage, LinksPage } from '@netinsight/plugin-time-transfer-ui';
import { SyncInputsUiPage } from '@netinsight/plugin-sync-inputs-ui';
import { GroupCalibrationPage } from '../GroupCalibrationPage';
import { GroupCalibrationPresets } from '../GroupCalibrationPresets';
import { NestedTabLayout } from '@netinsight/management-app-common-react';
import { Navigate } from 'react-router';
import { NodeGraphPage } from '@netinsight/plugin-node-graph-ui';
import { SyncRegionConfigPage, SyncRegionStatusPage } from '@netinsight/plugin-sync-region-ui';

export const TimeNetworkPage = () => {
  return (
    <Page themeId="home">
      <HeaderWithAlarms title="Zyntai Time Network" />
      <TabbedLayout>
        <TabbedLayout.Route path="/" title="Links" tabProps={{ id: 'time-nodes-links-tab' }}>
          <LinksPage />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/sources" title="Sync Sources" tabProps={{ id: 'time-nodes-sync-sources' }}>
          <SyncInputsUiPage />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/sync-regions" title="Sync regions" tabProps={{ id: 'sync-regions-tab' }}>
          <NestedTabLayout>
            <NestedTabLayout.Route index title="Index" element={<Navigate to="status" />} />
            <NestedTabLayout.Route path="status" title="Status" element={<SyncRegionStatusPage />} />
            <NestedTabLayout.Route path="config" title="Config" element={<SyncRegionConfigPage />} />
          </NestedTabLayout>
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/graph" title="Topology" tabProps={{ id: 'time-nodes-topology-tab' }}>
          <NodeGraphPage />
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/calibration" title="Calibration" tabProps={{ id: 'time-nodes-calibration-tab' }}>
          <NestedTabLayout>
            <NestedTabLayout.Route index title="Index" element={<Navigate to="status" />} />
            <NestedTabLayout.Route path="status" title="Status" element={<GroupCalibrationPage />} />
            <NestedTabLayout.Route path="presets" title="Groups" element={<GroupCalibrationPresets />} />
          </NestedTabLayout>
        </TabbedLayout.Route>
        <TabbedLayout.Route
          path="/link-options"
          title="Global settings"
          tabProps={{ id: 'time-network-global-link-options-tab' }}
        >
          <GlobalLinkOptionsPage />
        </TabbedLayout.Route>
      </TabbedLayout>
    </Page>
  );
};
