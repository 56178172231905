import { scaleOrdinal } from 'd3-scale';
import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { Button, MenuItem, ButtonProps, Menu, ListItemText } from '@material-ui/core';
import { A, F } from '@mobily/ts-belt';

export type MenuButtonProps = {
  options?: [string, any][];
  selectedOption?: any;
  onOptionSelected?: (selectedValue: any) => void;
  buttonTextFormatter?: (selectedValue: any, selectedLabel: string) => string;
} & Omit<ButtonProps, 'onClick' | 'aria-haspopup' | 'ref'>;

export const MenuButton: FunctionComponent<MenuButtonProps> = ({
  buttonTextFormatter = F.identity,
  selectedOption,
  onOptionSelected = F.ignore,
  options = [],
  ...buttonProps
}) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const handleSelect = useCallback(
    (key: string) => {
      onOptionSelected(key);
      setOpen(false);
    },
    [onOptionSelected],
  );
  const handleToggle = useCallback(() => {
    setOpen(prevOpen => !prevOpen);
  }, []);
  const handleClose = useCallback((event: any) => {
    if (anchorRef.current?.contains(event.target) ?? false) {
      return;
    }
    setOpen(false);
  }, []);

  const [values, labels] = A.unzip(options);
  const reverseLookup = scaleOrdinal(values, labels).unknown('');
  return (
    <>
      <Button aria-haspopup="true" onClick={handleToggle} ref={anchorRef} {...buttonProps}>
        {buttonTextFormatter(selectedOption, reverseLookup(selectedOption))}
      </Button>
      <Menu anchorEl={anchorRef.current} open={open} onClose={handleClose} style={{ transform: 'translate(0, 3rem)' }}>
        {options.map(([key, desc]) => (
          <MenuItem key={key} onClick={() => handleSelect(key)} selected={key === selectedOption}>
            <ListItemText primary={desc} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
