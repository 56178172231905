import React, { FunctionComponent, ReactNode, useEffect, useRef } from 'react';
import { usePrevious } from 'react-use';
import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab';
import WarningIcon from '@material-ui/icons/Warning';
import { F, G } from '@mobily/ts-belt';
import { ConfirmationCallback, useSubmitConfirmation } from './hooks';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  container: {
    order: 99,
    flexBasis: '100%',
  },
}));

type ConfirmButtonProps = ButtonProps & {
  confirmationMessage: ReactNode;
  onConfirmed: VoidFunction;
  onCanceled: VoidFunction;
  alertColor?: AlertProps['color'];
  cancelButtonProps?: ButtonProps;
  ['data-testid']?: string;
};

export const ConfirmSubmitButton: FunctionComponent<ConfirmButtonProps> = ({
  confirmationMessage,
  onClick,
  onConfirmed = F.ignore,
  onCanceled = F.ignore,
  alertColor = 'error',
  type: buttonType,
  ['data-testid']: testId,
  children,
  cancelButtonProps: { onClick: onCancelClick, ...cancelButtonProps } = {},
  ...buttonProps
}) => {
  const styles = useStyles();
  const prevMessage = usePrevious(confirmationMessage);
  const confirmContainerRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (G.isNotNullable(confirmationMessage) && G.isNullable(prevMessage) && confirmContainerRef.current) {
      confirmContainerRef.current.scrollIntoView();
    }
  }, [prevMessage, confirmationMessage]);
  return (
    <>
      <Button
        {...buttonProps}
        type={buttonType}
        onClick={onClick}
        data-testid={testId}
        disabled={G.isNotNullable(confirmationMessage) || buttonProps.disabled}
      >
        {children}
      </Button>
      {G.isNotNullable(confirmationMessage) ? (
        <>
          <Alert variant="outlined" color={alertColor} icon={<WarningIcon />} className={styles.container}>
            {confirmationMessage}
            <div className={styles.buttonContainer}>
              <Button
                {...buttonProps}
                type={buttonType}
                onClick={onConfirmed}
                color="secondary"
                variant="contained"
                size="small"
                data-testid={testId ? `${testId}-confirm` : undefined}
              >
                {children}
              </Button>
              <Button
                variant="outlined"
                color="default"
                size="small"
                {...cancelButtonProps}
                onClick={onCanceled}
                ref={confirmContainerRef}
              >
                {cancelButtonProps.children ?? 'Cancel'}
              </Button>
            </div>
          </Alert>
        </>
      ) : null}
    </>
  );
};

export const ConfirmButton: FunctionComponent<
  Omit<ConfirmButtonProps, 'onConfirmed' | 'onCanceled' | 'confirmationMessage'> & {
    confirmation: ReactNode | ConfirmationCallback;
  }
> = ({ confirmation, onClick: outerOnClick, ...confirmButtonProps }) => {
  const { onClick, onConfirmed, onCanceled, confirmationMessage } = useSubmitConfirmation({
    submitCallback: outerOnClick as any,
    confirmationCallback: G.isFunction(confirmation) ? (confirmation as ConfirmationCallback) : F.always(confirmation),
  });

  return (
    <ConfirmSubmitButton
      onClick={onClick}
      onConfirmed={onConfirmed}
      onCanceled={onCanceled}
      confirmationMessage={confirmationMessage}
      {...confirmButtonProps}
    />
  );
};
