import { useCallback } from 'react';
import { FieldErrors, useFormContext, useWatch } from 'react-hook-form';
import { A, O, pipe, S } from '@mobily/ts-belt';
import { PersistedSyncLink } from '@netinsight/management-app-common-api';
import { TextFieldProps, isNullableOrEmpty } from '@netinsight/management-app-common-react';
import { InterfaceInfo, isManagement, useInterfaceInfos } from '@netinsight/plugin-node-manager-ui';
import { AdminStatus } from '@netinsight/node-manager-schema';

const shouldDisableIface = (ifaceInfo: InterfaceInfo) =>
  ifaceInfo.adminStatus !== AdminStatus.Up || isNullableOrEmpty(ifaceInfo.ip) || isManagement(ifaceInfo);

const getNodeNetworkOptionProps = (nodeInterfaces: InterfaceInfo[]) =>
  A.concat(
    [
      {
        value: '-1',
        label: '',
        ['data-name']: '',
        ['data-vlanid']: undefined,
        disabled: false,
      },
    ],
    nodeInterfaces.map((ifaceInfo, index) => ({
      value: index.toString(10),
      label: `${ifaceInfo.ip ?? '?'} (${ifaceInfo.displayName})`,
      disabled: shouldDisableIface(ifaceInfo),
      ['data-vlanid']: ifaceInfo.vlanId,
      ['data-name']: ifaceInfo.name,
    })) ?? [],
  );

export const useNetworkInterfaceSelect = ({
  nodeId,
  prefix,
  errors,
}: {
  nodeId: string;
  prefix: 'endpointA' | 'endpointB';
  errors: FieldErrors<PersistedSyncLink>;
}): Partial<TextFieldProps> & { interfaces: ReturnType<typeof getNodeNetworkOptionProps>; isLoading: boolean } => {
  const { setValue, control } = useFormContext<PersistedSyncLink>();
  const { iface, vlanId } = useWatch({ control, name: prefix });
  const { data: allInterfaces, isLoading: isLoadingAllInterfaces } = useInterfaceInfos({
    nodeId,
    statuses: [],
    includeVlan: true,
    includeManagement: true,
  });
  const selectedValue =
    allInterfaces.findIndex(item => item.name === iface && item.vlanId === vlanId).toString(10) ?? '-1';

  const handleNetworkValueChange: NonNullable<TextFieldProps['onChange']> = useCallback(
    evt => {
      const selectedOption = evt.target.querySelector(`option[value="${evt.target.value}"]`);
      if (selectedOption) {
        const selectedIfaceName = selectedOption.getAttribute('data-name');
        const selectedVlanId = pipe(
          selectedOption.getAttribute('data-vlanid'),
          O.fromNullable,
          O.flatMap(v => O.fromExecution(() => parseInt(v, 10))),
          O.toUndefined,
        );
        setValue(`${prefix}.iface`, selectedIfaceName ?? '');
        setValue(`${prefix}.vlanId`, selectedVlanId);
      } else {
        setValue(`${prefix}.iface`, '');
        setValue(`${prefix}.vlanId`, undefined);
      }
    },
    [prefix, setValue],
  );
  return {
    value: selectedValue,
    onChange: handleNetworkValueChange,
    error: S.isNotEmpty(errors[prefix]?.iface?.message ?? '') || S.isNotEmpty(errors[prefix]?.vlanId?.message ?? ''),
    helperText: errors[prefix]?.iface?.message ?? errors[prefix]?.vlanId?.message,
    interfaces: getNodeNetworkOptionProps(allInterfaces),
    isLoading: isLoadingAllInterfaces,
  };
};
