import { initContract } from '@ts-rest/core';
import StatusCodes from 'http-status-codes';
import { JsonWebTokenSchema, WrappedErrorSchema } from './types';
import { OnboardingRequestSpecSchema, OnboardingRequestStatusSchema } from '@netinsight/crds';
import z from 'zod';

const c = initContract();

export const OnboardingRequestSchema = z.object({
  id: z.string(),
  spec: OnboardingRequestSpecSchema,
  status: OnboardingRequestStatusSchema,
});

export type OnboardingRequest = z.infer<typeof OnboardingRequestSchema>;

export const onboardingApi = c.router({
  listOnboardingRequests: {
    method: 'GET',
    path: '/onboarding/requests',
    summary: 'List onboarding requests',
    responses: {
      [StatusCodes.OK]: z.array(OnboardingRequestSchema),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  deleteOnboardingRequest: {
    method: 'DELETE',
    path: '/onboarding/requests/:requestId',
    summary: 'Remove onboarding request',
    body: null,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  createOnboardingToken: {
    method: 'POST',
    path: '/onboarding/token',
    summary: 'Create onboarding token',
    body: null,
    responses: {
      [StatusCodes.OK]: JsonWebTokenSchema,
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },

  createReplacementToken: {
    method: 'POST',
    path: '/onboarding/replace/:nodeId',
    pathParams: z.object({ nodeId: z.string() }),
    summary: 'Create replacement token for node',
    body: null,
    responses: {
      [StatusCodes.OK]: JsonWebTokenSchema,
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: z.unknown(),
    },
  },
});
