import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwrMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import { errorFromWrappedError, k8sTTLinksApiRef } from '@netinsight/management-app-common-react';
import { PersistedSyncLink, ValidationError } from '@netinsight/management-app-common-api';
import { TTLinksKey } from './useTTLinks';
import { isFieldErrors } from '../utils';

export const useTTLinkCreate = () => {
  const ttLinksApi = useApi(k8sTTLinksApiRef);

  const create = useCallback(
    async (_: string, { arg }: { arg: Omit<PersistedSyncLink, 'id'> }) => {
      const createLinkResponse = await ttLinksApi.createTimeTransferLink({
        body: arg,
      });
      if (createLinkResponse.status === StatusCodes.OK) {
        return [createLinkResponse.body.config];
      }
      if (
        createLinkResponse.status === StatusCodes.BAD_REQUEST &&
        isFieldErrors(createLinkResponse.body?.error?.details)
      ) {
        throw new ValidationError(createLinkResponse.body.error.details);
      }
      throw errorFromWrappedError(createLinkResponse.status, createLinkResponse.body);
    },
    [ttLinksApi],
  );
  return useSwrMutation(TTLinksKey, create, {
    populateCache: (createdLinks, links) => [...createdLinks, ...(links ?? [])],
    revalidate: false,
  });
};
