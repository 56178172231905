import React, { FunctionComponent, useEffect, useCallback } from 'react';
import { useController, useForm } from 'react-hook-form';
import { usePrevious } from 'react-use';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import { F } from '@mobily/ts-belt';
import {
  CheckboxField,
  getSelectOptionsFromSchema,
  TextField,
  useFormStyles,
  useTextFieldController,
  useTimeTransferConfigUpdate,
} from '@netinsight/management-app-common-react';
import { HiccConfig, HiccConfigSchema } from '@netinsight/crds';

export type TimeNodeHiccConfigFormProps = {
  initialValues: HiccConfig;
  defaultValues: HiccConfig;
  nodeId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    margin: theme.spacing(2, 0),
  },
  rowLabel: {
    minWidth: '4rem',
    fontWeight: 'bold',
  },
}));

export const TimeNodeHiccConfigForm: FunctionComponent<TimeNodeHiccConfigFormProps> = ({
  initialValues,
  defaultValues,
  nodeId,
}) => {
  const formStyles = useFormStyles();
  const styles = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<HiccConfig>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: zodResolver(HiccConfigSchema),
  });

  const prevInitialValues = usePrevious(initialValues);
  useEffect(() => {
    if (!F.equals(prevInitialValues, initialValues)) {
      reset(initialValues, { keepSubmitCount: true, keepIsSubmitted: true, keepValues: true });
    }
  }, [reset, prevInitialValues, initialValues]);

  const { trigger: updateConfig } = useTimeTransferConfigUpdate(nodeId);
  const handleOnSubmit = useCallback(
    async (updatedHiccConfig: HiccConfig) => {
      await updateConfig(existingConfig => ({
        ...existingConfig,
        hicc: updatedHiccConfig,
      }));
    },
    [updateConfig],
  );

  const handleResetToDefault = useCallback(() => {
    reset(defaultValues, { keepValues: false });
  }, [reset, defaultValues]);

  const { field: tenMhzBandwidthFieldProps } = useTextFieldController({
    control,
    schema: HiccConfigSchema.shape['10MBandwidth'],
    name: '10MBandwidth',
    label: '10M bandwidth (Hz)',
  });

  const { field: tenMhzDampingFieldProps } = useTextFieldController({
    control,
    schema: HiccConfigSchema.shape['10MDamping'],
    name: '10MDamping',
    label: '10M damping',
  });

  const { field: tenMhzMaxControlSignalFieldProps } = useTextFieldController({
    control,
    schema: HiccConfigSchema.shape['10MMaxControlSignal'],
    name: '10MMaxControlSignal',
    label: '10M max control signal',
  });

  const { field: ppsout10MArchFieldProps } = useTextFieldController({
    control,
    schema: HiccConfigSchema.shape.ppsout10MArchitecture,
    name: 'ppsout10MArchitecture',
    label: '10M/PPSOut architecture',
  });

  const { field: profileRttMismatchProps } = useTextFieldController({
    control,
    name: 'profileMaxRttMismatch',
    schema: HiccConfigSchema.shape.profileMaxRttMismatch.sourceType(),
    label: 'Profile max RTT mismatch (seconds)',
  });
  const deleteProfileOnChangeCheckboxProps = useController({ control, name: 'deleteProfilesOnChange' });
  const dynamicLinkChangeThresholdCheckboxProps = useController({ control, name: 'dynamicLinkChangeThreshold' });
  const DynamicLinkChangeThresholdParamsSchema = HiccConfigSchema.shape.dynamicLinkChangeThresholdParams;
  const { field: dynamicThresholdGoodSigmaProps } = useTextFieldController({
    schema: DynamicLinkChangeThresholdParamsSchema.shape.good.shape.sigma,
    control,
    name: 'dynamicLinkChangeThresholdParams.good.sigma',
    label: 'Sigma',
  });

  const { field: dynamicThresholdGoodHProps } = useTextFieldController({
    schema: DynamicLinkChangeThresholdParamsSchema.shape.good.shape.h,
    control,
    name: 'dynamicLinkChangeThresholdParams.good.h',
    label: 'H',
  });

  const { field: dynamicThresholdBadSigmaProps } = useTextFieldController({
    schema: DynamicLinkChangeThresholdParamsSchema.shape.bad.shape.sigma,
    control,
    name: 'dynamicLinkChangeThresholdParams.bad.sigma',
    label: 'Sigma',
  });

  const { field: dynamicThresholdBadHProps } = useTextFieldController({
    schema: DynamicLinkChangeThresholdParamsSchema.shape.bad.shape.h,
    control,
    name: 'dynamicLinkChangeThresholdParams.bad.h',
    label: 'H',
  });

  const { field: dynamicThresholdMinimumProps } = useTextFieldController({
    schema: DynamicLinkChangeThresholdParamsSchema.shape.minimum,
    control,
    name: 'dynamicLinkChangeThresholdParams.minimum',
    label: 'Minimum',
  });

  const enableTimeDifferenceProps = useController({ control, name: 'enableTimeDifference' });
  const enableTimeDifferencePercentilesProps = useController({
    control,
    name: 'enableTimeDifferencePercentiles',
  });

  const enableInfluxPpsInProps = useController({ control, name: 'enableInfluxPpsIn' });
  const enableInfluxTsProcBasicProps = useController({ control, name: 'enableInfluxTsProcBasic' });
  const enableInfluxTsProcDetailsProps = useController({ control, name: 'enableInfluxTsProcDetails' });

  const { field: linkChangeThresholdProps } = useTextFieldController({
    schema: HiccConfigSchema.shape.linkChangeThreshold.sourceType(),
    control,
    name: 'linkChangeThreshold',
    label: 'Link change threshold (seconds)',
  });

  const { field: linkMinAgeToBecomeStableProps } = useTextFieldController({
    schema: HiccConfigSchema.shape.linkMinAgeToBecomeStable,
    control,
    name: 'linkMinAgeToBecomeStable',
    label: 'Link min age to become stable (seconds)',
  });

  const { field: linkNoiseProps } = useTextFieldController({
    schema: HiccConfigSchema.shape.linkNoise.sourceType(),
    control,
    name: 'linkNoise',
    label: 'Link noise (seconds)',
  });

  const { field: stabilityControlErrorToleranceProps } = useTextFieldController({
    schema: HiccConfigSchema.shape.stabilityControlErrorTolerance,
    control,
    name: 'stabilityControlErrorTolerance',
    label: 'Stability control error tolerance (seconds)',
  });

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} className={formStyles.formContainer}>
      <CheckboxField
        fieldProps={deleteProfileOnChangeCheckboxProps}
        label="Delete profiles on change"
        description={HiccConfigSchema.shape.deleteProfilesOnChange.description ?? ''}
      />
      <CheckboxField
        fieldProps={dynamicLinkChangeThresholdCheckboxProps}
        label="Dynamic link change threshold"
        description={HiccConfigSchema.shape.dynamicLinkChangeThreshold.description ?? ''}
      />
      <fieldset>
        <legend>
          <Typography variant="body1">Dynamic link change threshold params</Typography>
        </legend>
        <div className={formStyles.formRow}>
          <Typography variant="body1" className={styles.rowLabel}>
            Good
          </Typography>
          <TextField {...dynamicThresholdGoodSigmaProps} inputProps={{ step: 1e-9 }} />
          <TextField {...dynamicThresholdGoodHProps} inputProps={{ step: 1e-9 }} />
        </div>
        <Divider className={styles.divider} />
        <div className={formStyles.formRow}>
          <Typography variant="body1" className={styles.rowLabel}>
            Bad
          </Typography>
          <TextField {...dynamicThresholdBadSigmaProps} inputProps={{ step: 1e-7 }} />
          <TextField {...dynamicThresholdBadHProps} inputProps={{ step: 1e-6 }} />
        </div>
        <Divider className={styles.divider} />
        <TextField {...dynamicThresholdMinimumProps} inputProps={{ step: 1e-9 }} style={{ marginLeft: '5.5rem' }} />
      </fieldset>
      <TextField {...linkChangeThresholdProps} inputProps={{ step: 1e-6 }} />
      <TextField {...linkMinAgeToBecomeStableProps} inputProps={{ step: 1 }} />
      <TextField {...linkNoiseProps} inputProps={{ step: 1e-7 }} />
      <TextField {...profileRttMismatchProps} inputProps={{ step: 1e-9 }} />
      <TextField {...stabilityControlErrorToleranceProps} inputProps={{ step: 1e-8 }} />
      <Divider />
      <TextField {...tenMhzBandwidthFieldProps} inputProps={{ step: 1e-2 }} />
      <TextField {...tenMhzDampingFieldProps} inputProps={{ step: 1e-2 }} />
      <TextField {...tenMhzMaxControlSignalFieldProps} inputProps={{ step: 1e-7 }} />
      <TextField {...ppsout10MArchFieldProps} select SelectProps={{ native: true }}>
        {getSelectOptionsFromSchema(HiccConfigSchema.shape.ppsout10MArchitecture.optional())}
      </TextField>
      <Divider />
      <CheckboxField fieldProps={enableTimeDifferenceProps} label="Enable time difference metrics collection" />
      <CheckboxField
        fieldProps={enableTimeDifferencePercentilesProps}
        label="Enable time difference percentiles metrics collection"
      />
      <CheckboxField fieldProps={enableInfluxPpsInProps} label="Enable extended PPS in metrics" />
      <CheckboxField fieldProps={enableInfluxTsProcBasicProps} label="Enable basic tsproc metrics" />
      <CheckboxField fieldProps={enableInfluxTsProcDetailsProps} label="Enable more tsproc metrics" />

      <div className={formStyles.formRow}>
        <Button
          type="submit"
          size="large"
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          data-testid="btn-submit"
        >
          Apply
        </Button>
        <Button
          type="button"
          size="large"
          color="default"
          variant="outlined"
          data-testid="btn-reset"
          onClick={handleResetToDefault}
        >
          Reset to default
        </Button>
      </div>
    </form>
  );
};
