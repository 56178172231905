import { G } from '@mobily/ts-belt';
import { TTLinkEndpoint } from '@netinsight/crds';

type FormatEndpointParams = Pick<TTLinkEndpoint, 'iface' | 'port' | 'vlanId'> & { ip?: string };

export const formatEndpoint = ({ ip, port, iface, vlanId }: FormatEndpointParams) => {
  const portSegment = G.isNotNullable(port) ? `:${port}` : '';
  const vlanSegment = G.isNotNullable(vlanId) ? `.${vlanId}` : '';
  return `${ip ?? '?'}${portSegment} (${iface ?? '?'}${vlanSegment})`;
};
