import React, { useState, useCallback, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Grid,
  Typography,
  Button,
  Switch,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  FormControlLabel,
  useMediaQuery,
  Box,
  useTheme,
  makeStyles,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { DateTime } from 'luxon';
import PlayIcon from '@material-ui/icons/PlayArrow';
import { Page, Content, InfoCard } from '@backstage/core-components';
import {
  useSnackbarHelper,
  useGroupCalibrationRun,
  useGroupCalibrationNextScheduledRun,
} from '@netinsight/management-app-common-react';
import { GROUP_CALIBRATION_DEFAULT_CONFIG, GroupCalibrationOptions } from '@netinsight/group-calibration-api';
import { GroupCalibrationTable } from '../GroupCalibrationTable';
import { useGroupCalibrationSpec } from '../../hooks';

const tempRun: GroupCalibrationOptions = {
  commit: false,
  preset: GROUP_CALIBRATION_DEFAULT_CONFIG.presets[0].name,
};

const useStyles = makeStyles(() => ({
  chip: {
    margin: 0,
  },
  table: {
    minWidth: 650,
  },
}));

interface ScheduleTableProps {
  rows: { id: number; nodeGroup: string; nextTime: string }[];
}

export const ScheduleTable = (props: ScheduleTableProps) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Scheduled Node Group</TableCell>
            <TableCell align="right">Next Run</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map(row => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row" data-testid="scheduled-group">
                {row.nodeGroup}
              </TableCell>
              <TableCell align="right" data-testid="scheduled-time">
                {DateTime.fromISO(row.nextTime ?? '').toRelative({ style: 'short' })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const GroupCalibrationPage = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [openRunDialog, setOpenRunDialog] = useState(false);
  const [run, setRun] = React.useState(tempRun);
  const { data: spec } = useGroupCalibrationSpec();
  const { snackbar } = useSnackbarHelper();
  const { trigger: runner } = useGroupCalibrationRun();
  const { data: activeSchedule } = useGroupCalibrationNextScheduledRun({ refreshInterval: 15000 });

  const hasActiveJobs = useMemo(() => (activeSchedule?.activeJobs.length ?? 0) > 0, [activeSchedule]);

  const handleChangePreset = useCallback(
    (event: any) => setRun({ ...run, preset: event.target.value as string }),
    [run],
  );

  const handleCloseRunDialog = () => setOpenRunDialog(false);

  const handleRunCalibration = () => {
    setOpenRunDialog(true);
  };

  const handleCommitSwitch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setRun({ ...run, commit: event.target.checked }),
    [run],
  );

  const scheduleRows = useMemo(() => {
    if (!activeSchedule?.scheduledJobs) return [];
    return activeSchedule.scheduledJobs;
  }, [activeSchedule]);

  const handleRun = useCallback(async () => {
    setOpenRunDialog(false);
    try {
      await runner(run);
    } catch (_err: any) {
      snackbar.error('Submit calibration');
    }
  }, [run, snackbar, runner]);

  return (
    <Page themeId="tool">
      <Content>
        <Grid container spacing={3} direction="column" justifyContent="center">
          <Grid item>
            <InfoCard
              title="Calibration"
              titleTypographyProps={{ variant: 'h5' }}
              subheader={
                <div>
                  <Typography variant="body2">
                    This page is used for doing group calibration. Group calibration means that all links around and
                    connecting the selected group of nodes are adjusted.
                  </Typography>
                </div>
              }
            >
              <Grid container spacing={2} direction="column" justifyContent="center">
                <Grid item>
                  <Box display="flex" flexDirection="row">
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<PlayIcon />}
                      disabled={hasActiveJobs}
                      onClick={handleRunCalibration}
                    >
                      Run Once...
                    </Button>
                  </Box>
                </Grid>
                <Grid item>
                  <ScheduleTable rows={scheduleRows} />
                </Grid>
              </Grid>
            </InfoCard>
          </Grid>
          <Grid item>
            <GroupCalibrationTable />
          </Grid>
        </Grid>
        <Dialog fullScreen={fullScreen} open={openRunDialog} onClose={handleCloseRunDialog}>
          <DialogTitle>Run Group Calibration</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Run a calibration once for a group of nodes. The calculated link adjustments will only be applied if the
              commit option is selected, else no changes are made.
            </DialogContentText>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="calibration-run-preset-label">Group</InputLabel>
                  <Select
                    id="calibration-run-preset"
                    labelId="calibration-run-preset-label"
                    value={run.preset}
                    label="Group"
                    onChange={handleChangePreset}
                  >
                    {(spec?.presets ?? []).map(preset => (
                      <MenuItem key={preset.name} value={preset.name}>
                        {preset.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch checked={run.commit} onChange={handleCommitSwitch} />}
                  label="Commit changes"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseRunDialog}>Cancel</Button>
            <Button variant="contained" onClick={handleRun}>
              Run
            </Button>
          </DialogActions>
        </Dialog>
      </Content>
    </Page>
  );
};
