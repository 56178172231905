import useSwr from 'swr';
import { errorFromWrappedError, k8sAlarmSubscriptionsApiRef } from '@netinsight/management-app-common-react';
import { useCallback } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import StatusCodes from 'http-status-codes';
import { AlarmSubscription, AlarmSubscriptionSpec } from '@netinsight/management-app-common-api';

export const useAlarmSubscriptions = () => {
  const alarmSubscriptionApi = useApi(k8sAlarmSubscriptionsApiRef);

  const fetcher = useCallback(async (): Promise<AlarmSubscription[]> => {
    const alarmSubscriptionResponse = await alarmSubscriptionApi.listAlarmSubscriptions();

    if (alarmSubscriptionResponse.status !== StatusCodes.OK) {
      throw errorFromWrappedError(alarmSubscriptionResponse.status, alarmSubscriptionResponse.body);
    }

    return alarmSubscriptionResponse.body;
  }, [alarmSubscriptionApi]);

  const { mutate, data, error, isLoading } = useSwr('alarmSubscriptions', fetcher, {
    refreshInterval: 0,
  });

  const deleteSubscription = useCallback(
    async (id: string) => {
      const result = await alarmSubscriptionApi.deleteAlarmSubscription({ params: { id } });
      if (result.status !== StatusCodes.OK) {
        throw errorFromWrappedError(result.status, (result as any).body);
      }
      await mutate();
    },
    [alarmSubscriptionApi, mutate],
  );

  const createSubscription = useCallback(
    async (body: AlarmSubscriptionSpec) => {
      const result = await alarmSubscriptionApi.createAlarmSubscription({ body });
      if (result.status !== StatusCodes.OK) {
        throw errorFromWrappedError(result.status, (result as any).body);
      }
      await mutate();
    },
    [alarmSubscriptionApi, mutate],
  );

  return { data, error, isLoading, deleteSubscription, createSubscription };
};
