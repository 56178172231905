import { useMemo } from 'react';
import { F, G, S } from '@mobily/ts-belt';
import { InventoryKinds, useInventory, useNodeManagerConfig } from '@netinsight/management-app-common-react';
import { AdminStatus, NodeState } from '@netinsight/node-manager-schema';
import { formatInterfaceName, mapInterfaces } from '../helpers';
import { InterfaceInfo, InterfaceUsage } from '../types';
import { NodeManagerConfig } from '@netinsight/management-app-common-api';
import { DEFAULT_NAMESPACES, ELECTRICAL_IFACES, MANAGEMENT_IFACE } from '../constants';

type InterfaceFilterOptions = {
  namespace?: string;
  statuses?: AdminStatus[];
  includeVlan?: boolean;
  includeManagement?: boolean;
  includeElectrical?: boolean;
  includePtpReceivers?: boolean;
  includePtpTransmitters?: boolean;
};

type InterfaceFilter = (input: InterfaceInfo) => boolean;

export const getInterfaceDisplayNameMap = (nodeManagerState: NodeState.NodeState | undefined) =>
  Object.fromEntries(
    nodeManagerState?.transceivers?.map(({ interfaceName, displayName }) => [interfaceName, displayName]) ?? [],
  );

const getInterfaceInfos = (
  nodeManagerConfig: NodeManagerConfig,
  nodeManagerState?: NodeState.NodeState,
  interfaceUsageMap?: Record<string, InterfaceUsage>,
): InterfaceInfo[] => {
  const interfaceDisplayNameMap = getInterfaceDisplayNameMap(nodeManagerState);

  return mapInterfaces(nodeManagerConfig, (iface, vlanIface) => {
    const interfaceDisplayName = interfaceDisplayNameMap[iface.name] ?? iface.name;
    return G.isNotNullable(vlanIface)
      ? {
          id: formatInterfaceName(iface.name, vlanIface.id),
          name: iface.name,
          displayName: formatInterfaceName(interfaceDisplayName, vlanIface.id),
          vlanId: vlanIface.id as number | undefined,
          adminStatus: vlanIface.adminStatus,
          networkNamespace: vlanIface.networkNamespace,
          ip: vlanIface.ipAddress?.replace(/\/.+/, ''),
          usage: interfaceUsageMap?.[formatInterfaceName(iface.name, vlanIface.id)] ?? { links: {} },
        }
      : {
          id: iface.name,
          name: iface.name,
          displayName: formatInterfaceName(interfaceDisplayName),
          networkNamespace: iface.networkNamespace,
          adminStatus: iface.adminStatus,
          ip: iface.ipAddress?.replace(/\/.+/, ''),
          vlanId: undefined as number | undefined,
          usage: interfaceUsageMap?.[formatInterfaceName(iface.name)] ?? { links: {} },
        };
  });
};

export const isElectrical = (name: string) => ELECTRICAL_IFACES.includes(name);
export const isManagement = ({ networkNamespace, name }: Pick<InterfaceInfo, 'networkNamespace' | 'name'>) =>
  networkNamespace === DEFAULT_NAMESPACES.management ||
  networkNamespace === DEFAULT_NAMESPACES.default ||
  name === MANAGEMENT_IFACE;

const combineFilters = ({
  statuses,
  namespace,
  includeVlan,
  includeElectrical,
  includeManagement,
  includePtpReceivers,
  includePtpTransmitters,
}: InterfaceFilterOptions) => {
  const statusFilter: InterfaceFilter =
    G.isNotNullable(statuses) && statuses.length > 0 ? i => statuses.includes(i.adminStatus) : F.always(true);
  const nsFilter: InterfaceFilter = F.allPass(namespace!, [G.isNotNullable, S.isNotEmpty])
    ? i => i.networkNamespace === namespace
    : F.always(true);
  const vlanFilter: InterfaceFilter = includeVlan ? F.always(true) : i => G.isNullable(i.vlanId);
  const managementFilter: InterfaceFilter = includeManagement ? F.always(true) : i => !isManagement(i);
  const electricalFilter: InterfaceFilter = includeElectrical ? F.always(true) : i => !isElectrical(i.id);
  const ptpTransmitterFilter: InterfaceFilter = includePtpTransmitters
    ? F.always(true)
    : i => G.isNullable(i.usage.ptpTransmitter);
  const ptpReceiverFilter: InterfaceFilter = includePtpReceivers
    ? F.always(true)
    : i => G.isNullable(i.usage.ptpReceiver);
  return F.allPass([
    statusFilter,
    nsFilter,
    vlanFilter,
    managementFilter,
    electricalFilter,
    ptpReceiverFilter,
    ptpTransmitterFilter,
  ]);
};

export const useInterfaceInfos = ({
  nodeId,
  usageMap,
  statuses = [AdminStatus.Up],
  namespace,
  includeVlan = false,
  includeManagement = false,
  includeElectrical = true,
  includePtpReceivers: includePtpReceivers = true,
  includePtpTransmitters: includePtpTransmitters = true,
}: InterfaceFilterOptions & { nodeId: string; usageMap?: Record<string, InterfaceUsage> }) => {
  const {
    config: nodeManagerConfig,
    loading: isLoadingNodeManagerConfig,
    error: nodeManagerConfigError,
  } = useNodeManagerConfig(nodeId);
  const {
    data: nodeManagerState,
    isLoading: isLoadingNodeManagerState,
    error: nodeManagerStateError,
  } = useInventory<NodeState.NodeState>({
    nodeId,
    kind: InventoryKinds.NodeManager,
  });
  const allInterfaces = useMemo(
    () =>
      G.isNotNullable(nodeManagerConfig)
        ? getInterfaceInfos(nodeManagerConfig, nodeManagerState?.data, usageMap ?? {})
        : [],
    [nodeManagerConfig, nodeManagerState, usageMap],
  );

  const filteredInterfaces = useMemo(
    () =>
      allInterfaces.filter(
        combineFilters({
          statuses,
          namespace,
          includeVlan,
          includeManagement,
          includeElectrical,
          includePtpReceivers: includePtpReceivers,
          includePtpTransmitters: includePtpTransmitters,
        }),
      ),
    [
      allInterfaces,
      statuses,
      namespace,
      includeVlan,
      includeManagement,
      includeElectrical,
      includePtpTransmitters,
      includePtpReceivers,
    ],
  );
  return {
    data: filteredInterfaces,
    isLoading: isLoadingNodeManagerConfig || isLoadingNodeManagerState,
    error: nodeManagerConfigError ?? nodeManagerStateError,
  };
};
