import React, { FunctionComponent, ReactNode, useCallback, useRef, useState } from 'react';
import cx from 'classnames';
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';
import { IconButton, IconButtonProps, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';

export type InputTooltipProps = { text?: ReactNode; label?: string } & Omit<
  IconButtonProps,
  'size' | 'ref' | 'onClick'
>;

const useStyles = makeStyles(
  (theme: Theme) => ({
    button: {
      color: theme.palette.text.hint,
    },
  }),
  { name: 'NetiTooltip' },
);

export const InputTooltip: FunctionComponent<InputTooltipProps> = ({
  text,
  label = 'Open tooltip',
  className,
  ...otherProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const styles = useStyles();
  const theme = useTheme();
  const handleToggleTooltip = useCallback(() => {
    setIsOpen(prevState => !prevState);
  }, []);

  const handleClose = useCallback(() => setIsOpen(false), []);
  const buttonRef = useRef<HTMLButtonElement>(null);
  if (!text) {
    return null;
  }
  return (
    <>
      <IconButton
        onClick={handleToggleTooltip}
        size="small"
        ref={buttonRef}
        className={cx(styles.button, className)}
        {...otherProps}
      >
        <InfoIcon color="disabled" />
        <Typography variant="srOnly">{label}</Typography>
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        <Typography
          variant="body2"
          style={{
            padding: theme.spacing(1),
            maxWidth: '33vw',
          }}
        >
          {text}
        </Typography>
      </Popover>
    </>
  );
};
