import React, { useCallback, useMemo } from 'react';
import {
  Content,
  InfoCard,
  Link,
  Progress,
  ResponseErrorPanel,
  StructuredMetadataTable,
  Table,
  TableColumn,
} from '@backstage/core-components';
import { useSystemSoftware, useTimeNodes } from '../../hooks';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { TimeNode } from '@netinsight/management-app-common-api';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import RestoreIcon from '@material-ui/icons/Restore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { k8sNodeApiRef, useSnackbarHelper, useTableState } from '@netinsight/management-app-common-react';
import { useApi } from '@backstage/core-plugin-api';
import { DEFAULT_SYNC_REGION, TimeNodeStatusHelper } from '@netinsight/crds';
import { DateTime } from 'luxon';
import {
  SyncRegionSelector,
  compareSyncRegion,
  useFilterBySyncRegion,
  useSyncRegions,
} from '@netinsight/plugin-sync-region-ui';

const useStyles = makeStyles(theme => ({
  empty: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const TimeNodeUpgradeTable = () => {
  const classes = useStyles();
  const nodeApi = useApi(k8sNodeApiRef);
  const { snackbar } = useSnackbarHelper();
  const { data: versions, error: versionsError, loading: versionsLoading } = useSystemSoftware();
  const { data: nodes, error: nodesError, isLoading: nodesLoading, mutate } = useTimeNodes({ interval: 5000 });
  const { data: syncRegions } = useSyncRegions();
  const [rollbackDialog, setRollbackDialog] = React.useState<boolean>(false);
  const [rollbackNode, setRollbackNode] = React.useState<string>('unknown');
  const [rollbackNodeName, setRollbackNodeName] = React.useState<string>('unknown');
  const [rollbackVersion, setRollbackVersion] = React.useState<string>('unknown');

  const handleUpgrade = useCallback(
    async (nodeId: any) => {
      await nodeApi.upgradeNode({ params: { nodeId } });
      await mutate();
      snackbar.info('Upgrade requested');
    },
    [nodeApi, mutate, snackbar],
  );

  const handleRollback = (nodeId: string, nodeName: string, previousRelease: string) => {
    setRollbackNode(nodeId);
    setRollbackNodeName(nodeName);
    setRollbackVersion(previousRelease);
    setRollbackDialog(true);
  };

  const handleRollbackCancel = () => setRollbackDialog(false);

  const handleConfirmedRollback = useCallback(
    async (nodeId: string, version: string) => {
      setRollbackDialog(false);
      await nodeApi.rollbackNode({ params: { nodeId } });
      await mutate();
      snackbar.info(`Rollback to version ${version} requested`);
    },
    [nodeApi, mutate, snackbar],
  );

  const allowUpgrade = (data: TimeNode) =>
    data.spec.lifecycleState === 'Up' && data.spec.software?.requestedRelease !== versions?.version.release;
  const allowDowngrade = (data: TimeNode) =>
    data.spec.lifecycleState === 'Up' && data.status?.software?.previousRelease;

  const upgradeButton = (data: TimeNode) => {
    if (
      data.spec.lifecycleState === 'Up' &&
      data.status?.software?.previousRelease &&
      data.spec.software?.requestedRelease &&
      data.spec.software?.requestedRelease !== data.status?.software?.previousRelease &&
      data.spec.software?.requestedRelease === versions?.version.release
    ) {
      return (
        <Button
          onClick={() =>
            handleRollback(data.id, data.spec.name ?? data.id, data.status?.software?.previousRelease ?? 'unknown')
          }
          size="small"
          color="secondary"
          variant="contained"
          disabled={!allowDowngrade(data)}
          data-testid="row-btn-upgrade"
          startIcon={<RestoreIcon />}
        >
          Rollback{data.status?.software?.previousRelease ? ` to ${data.status?.software?.previousRelease}` : ''}
        </Button>
      );
    }
    return (
      <Button
        onClick={() => handleUpgrade(data.id)}
        size="small"
        color="primary"
        variant="contained"
        disabled={!allowUpgrade(data)}
        data-testid="row-btn-upgrade"
        startIcon={<SystemUpdateIcon />}
      >
        Upgrade{versions?.version.release ? ` to ${versions?.version.release}` : ''}
      </Button>
    );
  };

  const columns: TableColumn<TimeNode>[] = [
    {
      title: 'Name',
      field: 'spec.name',
      highlight: true,
      sorting: true,
      defaultSort: 'asc',
      customSort: (a, b) => (a.spec.name ?? a.id).localeCompare(b.spec.name ?? b.id),
      render: row => (
        <Link data-testid="row-node-name" to={`/nodes/info/${row.id}`}>
          {row.spec.name ?? row.id}
        </Link>
      ),
    },
    {
      title: 'Region',
      field: 'spec.syncRegion',
      render: data => data.spec.syncRegion ?? DEFAULT_SYNC_REGION,
      customSort: (row1, row2) =>
        compareSyncRegion(row1.spec.syncRegion ?? DEFAULT_SYNC_REGION, row2.spec.syncRegion ?? DEFAULT_SYNC_REGION),
    },
    {
      title: 'Requested release',
      field: 'spec.software.requestedRelease',
      render: data => (
        <Typography data-testid="row-requested-release">{data.spec.software?.requestedRelease ?? 'N/A'}</Typography>
      ),
    },
    {
      title: 'Installed release',
      field: 'status.software.installedRelease',
      render: data => (
        <Typography data-testid="row-installed-release">
          {data.status?.software?.installedRelease ?? 'Unknown'}
        </Typography>
      ),
    },
    {
      title: 'Message',
      sorting: false,
      render: data => {
        const status = data.status?.conditions?.find(cond => cond.type === TimeNodeStatusHelper.Upgrade.type);
        return (
          <>
            <Typography data-testid="row-message">{status?.message ?? '-'}</Typography>
            <Typography variant="body2" color="textSecondary">
              {status?.lastTransitionTime ? DateTime.fromISO(status?.lastTransitionTime as any).toRelative() : ''}
            </Typography>
          </>
        );
      },
    },
    {
      title: 'Action',
      sorting: false,
      render: upgradeButton,
    },
  ];

  const showPagination = nodes && nodes.length > 20;

  const tableOptions = useMemo(
    () => ({
      paging: showPagination,
      pageSize: 20,
      actionsColumnIndex: -1,
      loadingType: 'linear',
      showEmptyDataSourceMessage: !nodesLoading,
      padding: 'dense',
      pageSizeOptions: [20, 50, 100],
      thirdSortClick: false,
    }),
    [showPagination, nodesLoading],
  );
  const {
    items: filteredRows,
    onSyncRegionChange,
    syncRegion,
  } = useFilterBySyncRegion(nodes ?? [], node => [node.spec.syncRegion ?? DEFAULT_SYNC_REGION]);

  const tableStates = useTableState<TimeNode>(columns, tableOptions as any, 'time-node-upgrade-table');

  return (
    <Content>
      <Grid>
        <Grid item>{versionsError && <ResponseErrorPanel error={versionsError} />}</Grid>
        <Grid item>{nodesError && <ResponseErrorPanel error={nodesError} />}</Grid>
        {versionsLoading && (
          <Grid item>
            <Progress />
          </Grid>
        )}
        {versions && (
          <Grid item>
            <InfoCard title="Overview" variant="gridItem">
              <StructuredMetadataTable
                metadata={{
                  release: versions.version.release,
                }}
                dense
              />
            </InfoCard>
          </Grid>
        )}
        {versions && (
          <Grid item>
            <SyncRegionSelector
              currentValue={syncRegion}
              syncRegions={syncRegions ?? []}
              onChange={onSyncRegionChange}
            />
            <Table<TimeNode>
              isLoading={nodesLoading}
              title="TimeNodes"
              data={filteredRows}
              emptyContent={<Typography className={classes.empty}>No timenodes</Typography>}
              {...tableStates}
            />
          </Grid>
        )}
      </Grid>
      <Dialog
        open={rollbackDialog}
        onClose={handleRollbackCancel}
        aria-labelledby="rollback-dialog-title"
        aria-describedby="rollback-dialog-description"
      >
        <DialogTitle id="rollback-dialog-title">Confirm version rollback</DialogTitle>
        <DialogContent>
          <DialogContentText id="rollback-dialog-description">
            {`This will attempt to downgrade the node ${rollbackNodeName} to version '${rollbackVersion}'. Rolling back may
            fail! Do you want to roll back?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRollbackCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirmedRollback(rollbackNode, rollbackVersion)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Content>
  );
};
