/* eslint-disable no-nested-ternary */
import React, { ReactNode } from 'react';
import {
  ZodOptional,
  ZodUnion,
  ZodLiteral,
  ZodNativeEnum,
  preprocess,
  ZodSchema,
  ZodObject,
  ZodType,
  ZodNumber,
  ZodDefault,
  ZodTypeAny,
  ZodBoolean,
} from 'zod';
import { isNullableOrEmpty, isZodArray, isZodDefault, isZodOptional } from './guards';
import { isZodObject } from '@ts-rest/core';
import { G } from '@mobily/ts-belt';
import { onOffScale } from './scales';

export type UnionFieldSchemaType<T> = ZodOptional<ZodUnion<[ZodLiteral<T>, ...ZodLiteral<T>[]]>>;

type ZodDefaultOrOptional<T extends ZodTypeAny> =
  | ZodOptional<ZodBoolean>
  | ZodDefault<ZodBoolean>
  | ZodOptional<ZodDefault<ZodBoolean>>
  | ZodDefault<ZodOptional<ZodBoolean>>
  | ZodDefault<ZodOptional<T> | ZodOptional<ZodDefault<T>>>;

export function getOptionsAndDescriptionsFromSchema<T>(
  schema: UnionFieldSchemaType<T>,
  defaultValue?: T,
): [T | null, string | undefined][] {
  return schema
    .unwrap()
    .options.map(opt => [
      opt.value,
      G.isNotNullable(defaultValue) && opt.value === defaultValue && !isNullableOrEmpty(opt.description)
        ? `${opt.description} (default)`
        : opt.description,
    ]);
}

export function getSelectOptionFromPair([val, desc]: [any, any]) {
  // eslint-disable-next-line eqeqeq
  return val != null || desc != null
    ? React.createElement('option', { key: val, value: val }, desc ?? val?.toString())
    : null;
}

export function getSelectOptionsFromSchema<T extends string | number>(
  schema: UnionFieldSchemaType<T>,
  defaultValue?: T,
): ReactNode {
  return React.createElement(
    React.Fragment,
    {},
    getOptionsAndDescriptionsFromSchema(schema, defaultValue).map(getSelectOptionFromPair),
  );
}

export const getOptionsAndDescriptionsFromNativeEnumSchema = (schema: ZodNativeEnum<any>) =>
  Object.entries(schema.enum)
    .filter(([k]) => isNaN(Number(k)))
    .map(pair => pair.reverse() as [any, string]);

export function getSelectOptionsFromNativeEnumSchema(schema: ZodNativeEnum<any>): ReactNode {
  return React.createElement(
    React.Fragment,
    {},
    getOptionsAndDescriptionsFromNativeEnumSchema(schema).map(getSelectOptionFromPair),
  );
}
// React web forms like empty strings and they need to be converted to undefined to satify optional fields
export function convertZodSchemaToWebFormSchema(topSchema: ZodSchema<any>): ZodSchema<any> {
  function eliminateEmptyStrings(schema: ZodSchema<any>, value: any): any {
    if (value === undefined) return undefined;

    // remove empty strings if the field is optional
    if (schema.isOptional() && value === '') return undefined;

    let innerSchema = isZodOptional(schema) ? schema.unwrap() : schema;
    innerSchema = isZodDefault(innerSchema) ? innerSchema.removeDefault() : innerSchema;

    if (isZodObject(innerSchema)) {
      Object.entries((innerSchema as ZodObject<any>).shape).forEach(([field, fieldSchema]) => {
        value[field] = eliminateEmptyStrings(fieldSchema as ZodType, value[field]);
      });
    }

    if (isZodArray(innerSchema)) {
      return value.map((v: any) => eliminateEmptyStrings(innerSchema.element, v));
    }

    return value;
  }

  // preprocess is preferred over transform here since preprocess is run before validation
  return preprocess(value => eliminateEmptyStrings(topSchema, value), topSchema);
}

export const unwrapSchema = <T extends ZodTypeAny>(schema: ZodDefaultOrOptional<T>): T => {
  let innerSchema = isZodOptional(schema) ? schema.unwrap() : isZodDefault(schema) ? schema.removeDefault() : schema;
  innerSchema = isZodDefault(innerSchema)
    ? innerSchema.removeDefault()
    : isZodOptional(innerSchema)
      ? innerSchema.unwrap()
      : innerSchema;
  return innerSchema as T;
};

export const getTooltipForNumberSchema = (schema: ZodNumber, description = schema.description): ReactNode => {
  if (isNullableOrEmpty(description)) {
    return null;
  }

  return React.createElement(
    'div',
    {},
    React.createElement('div', {}, description),
    G.isNotNullable(schema.minValue) && !isNaN(schema.minValue) && schema.minValue !== -Infinity
      ? React.createElement(
          'div',
          {},
          `Min: ${
            schema.minValue !== 0 && schema.minValue < 1e-3 ? schema.minValue.toExponential(3) : schema.minValue
          }`,
        )
      : null,
    G.isNotNullable(schema.maxValue) && !isNaN(schema.maxValue) && schema.maxValue !== Infinity
      ? React.createElement(
          'div',
          {},
          `Max: ${
            schema.maxValue !== 0 && schema.maxValue < 1e-3 ? schema.maxValue.toExponential(3) : schema.maxValue
          }`,
        )
      : null,
  );
};

export const getTooltipForBooleanSchema = (
  schema: ZodBoolean,
  defaultValue: boolean | undefined,
  description = schema.description,
): ReactNode => {
  if (isNullableOrEmpty(description)) {
    return null;
  }

  return React.createElement(
    'div',
    {},
    React.createElement('div', {}, description),
    G.isNotNullable(defaultValue) ? React.createElement('div', {}, `Default:  ${onOffScale(defaultValue)}`) : null,
  );
};

export const getTooltipForWrappedNumberSchema = (schema: ZodDefaultOrOptional<ZodNumber>) => {
  const innerSchema = unwrapSchema(schema);
  return getTooltipForNumberSchema(innerSchema);
};

export const getTooltipForWrappedBooleanSchema = (schema: ZodDefaultOrOptional<ZodBoolean>, defaultValue?: boolean) => {
  const innerSchema = unwrapSchema(schema);
  return getTooltipForBooleanSchema(innerSchema, defaultValue, schema.description);
};
