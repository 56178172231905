import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { getScalarResultFromPromise, metricsApiRef } from '@netinsight/management-app-common-react';

export const useNtpTimeStatuses = ({ nodeId }: { nodeId: string }, options?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);

  return useSwr(
    [nodeId, 'useNtpTimeStatuses'],
    async ([nodeIdParam]) => {
      const [estErrorResult, timeFrequencyResult, intervalsResult, maxErrorResult, offsetResult, precisionResult] =
        await Promise.allSettled([
          metricsApi.instantQuery({ query: `neti_ntp_time_est_error{nodeid="${nodeIdParam}"}` }),
          metricsApi.instantQuery({ query: `neti_ntp_time_frequency{nodeid="${nodeIdParam}"}` }),
          metricsApi.instantQuery({ query: `neti_ntp_time_intervals{nodeid="${nodeIdParam}"}` }),
          metricsApi.instantQuery({ query: `neti_ntp_time_max_error{nodeid="${nodeIdParam}"}` }),
          metricsApi.instantQuery({ query: `neti_ntp_time_offset{nodeid="${nodeIdParam}"}` }),
          metricsApi.instantQuery({ query: `neti_ntp_time_precision{nodeid="${nodeIdParam}"}` }),
        ]);

      return {
        ...getScalarResultFromPromise(val => ({ estError: parseFloat(val) }))(estErrorResult),
        ...getScalarResultFromPromise(val => ({ timeFrequency: parseFloat(val) }))(timeFrequencyResult),
        ...getScalarResultFromPromise(val => ({ intervals: parseFloat(val) }))(intervalsResult),
        ...getScalarResultFromPromise(val => ({ maxError: parseFloat(val) }))(maxErrorResult),
        ...getScalarResultFromPromise(val => ({ offset: parseFloat(val) }))(offsetResult),
        ...getScalarResultFromPromise(val => ({ precision: parseFloat(val) }))(precisionResult),
      };
    },
    options,
  );
};
