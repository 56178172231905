import useSwr, { SWRConfiguration } from 'swr';
import { merge } from 'ts-deepmerge';
import { useApi } from '@backstage/core-plugin-api';
import { groupMetricsFromPromise, metricsApiRef } from '@netinsight/management-app-common-react';

export const useNtpServerStatuses = ({ nodeId }: { nodeId: string }, options?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);

  return useSwr(
    [nodeId, 'useNtpServerStatuses'],
    async ([nodeIdParam]) => {
      const [
        delayStatuses,
        jitterStatuses,
        offsetStatuses,
        pollIntervalStatuses,
        reachStatuses,
        selectedStatuses,
        stratumStatuses,
      ] = await Promise.allSettled([
        metricsApi.instantQuery({ query: `neti_ntp_status_delay{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_ntp_status_jitter{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_ntp_status_offset{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({
          query: `neti_ntp_status_poll_interval{nodeid="${nodeIdParam}"}`,
        }),
        metricsApi.instantQuery({ query: `neti_ntp_status_reach{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_ntp_status_selected{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_ntp_status_stratum{nodeid="${nodeIdParam}"}` }),
      ]);

      return merge(
        groupMetricsFromPromise(
          delayStatuses,
          metrics => metrics.remote,
          val => ({ delay: parseFloat(val) }),
        ),
        groupMetricsFromPromise(
          jitterStatuses,
          metrics => metrics.remote,
          val => ({ jitter: parseFloat(val) }),
        ),
        groupMetricsFromPromise(
          offsetStatuses,
          metrics => metrics.remote,
          val => ({ offset: parseFloat(val) }),
        ),
        groupMetricsFromPromise(
          pollIntervalStatuses,
          metrics => metrics.remote,
          val => ({ pollInterval: parseFloat(val) }),
        ),
        groupMetricsFromPromise(
          reachStatuses,
          metrics => metrics.remote,
          val => ({ reach: parseFloat(val) }),
        ),
        groupMetricsFromPromise(
          selectedStatuses,
          metrics => metrics.remote,
          val => ({ selected: Boolean(parseInt(val, 10)) }),
        ),
        groupMetricsFromPromise(
          stratumStatuses,
          metrics => metrics.remote,
          val => ({ stratum: parseFloat(val) }),
        ),
      );
    },
    options,
  );
};
