import { scaleOrdinal } from 'd3-scale';
import { G } from '@mobily/ts-belt';
import { PersistedLinkTarget } from '@netinsight/management-app-common-api';
import { LinkDirectionMeta, useNodeLinksByDirection } from '@netinsight/plugin-time-transfer-ui';
import { TimeTransferSpec } from '@netinsight/crds';
import { ValueOf } from '@netinsight/management-app-common-react';
import { SyncSourceUsage } from '@netinsight/syncd-schema';
import { useTimeNodeOverviewMetrics } from './hooks';
import {
  CONTROL_STATES,
  EXTERNAL_SYNC_SOURCES,
  SYNC_SOURCE_CONFIG_URLS,
  SYNC_SOURCE_LABELS,
  SYNC_SOURCE_NAMES,
} from './constants';

export const controlStateScale = scaleOrdinal(
  [CONTROL_STATES.Holdover, CONTROL_STATES.AbsTimeSymmetric, CONTROL_STATES.RelTime, CONTROL_STATES.AbsTime],
  ['Holdover', 'Abstime Symmetric', 'Reltime', 'Abstime'],
).unknown('Unknown');

export const isSyncSourceUsed = (usage?: SyncSourceUsage) =>
  usage === SyncSourceUsage.Selectable || usage === SyncSourceUsage.LocalOnly;

export const getLinkViewModels = ({
  nodeId,
  nodeNameMap,
  inLinks,
  outLinks,
  unusedLinks,
  syncSourceAvailabilities,
  syncSourcePriorities,
  linkDirectionMap,
}: {
  nodeId: string;
  nodeNameMap?: Record<string, string>;
} & Partial<Required<ReturnType<typeof useNodeLinksByDirection>['data']>> &
  Partial<Required<ReturnType<typeof useTimeNodeOverviewMetrics>['data']>>) => {
  const getLinkTargetViewModel = (lt: PersistedLinkTarget) => {
    const linkSelectionMeta = linkDirectionMap?.[lt.linkId]?.[nodeId];
    const peerNodeId = linkSelectionMeta?.peerNodeId;
    const clockNodeId = linkSelectionMeta?.clockNodeId;
    return {
      ...lt,
      peerNodeId: peerNodeId,
      peerNodeName: nodeNameMap?.[peerNodeId ?? ''] ?? peerNodeId,
      clockNodeId: clockNodeId,
      clockNodeName: nodeNameMap?.[clockNodeId ?? ''] ?? clockNodeId,
      selected: linkSelectionMeta?.selected ?? false,
      available: syncSourceAvailabilities?.[lt.linkId] ?? false,
      priority: syncSourcePriorities?.[lt.linkId],
    };
  };

  const sortByName = (
    { name: l1Name, linkId: l1Id }: ReturnType<typeof getLinkTargetViewModel>,
    { name: l2Name, linkId: l2Id }: ReturnType<typeof getLinkTargetViewModel>,
  ) => (l1Name ?? l1Id).localeCompare(l2Name ?? l2Id);
  return {
    inLinks: inLinks?.map(getLinkTargetViewModel).sort(sortByName) ?? [],
    outLinks: outLinks?.map(getLinkTargetViewModel).sort(sortByName) ?? [],
    unusedLinks: unusedLinks?.map(getLinkTargetViewModel).sort(sortByName) ?? [],
  };
};

export const getSyncSourceUsageConfigMap = (
  config?: TimeTransferSpec,
): Record<ValueOf<typeof SYNC_SOURCE_NAMES>, SyncSourceUsage | undefined> => ({
  [SYNC_SOURCE_NAMES.ppsIn]: config?.ppsIn?.usage,
  [SYNC_SOURCE_NAMES.gnss]: config?.gnss?.usage,
  [SYNC_SOURCE_NAMES.syncIn]:
    config?.syncIn?.selectable === true ? SyncSourceUsage.Selectable : SyncSourceUsage.NonSelectable,
  [SYNC_SOURCE_NAMES.ptp1]: config?.ptpClients?.instances?.[0]?.usage,
  [SYNC_SOURCE_NAMES.ptp2]: config?.ptpClients?.instances?.[1]?.usage,
  [SYNC_SOURCE_NAMES.osc]: undefined,
});

export const getSyncSourcePriorityConfigMap = (
  config?: TimeTransferSpec,
): Record<ValueOf<typeof SYNC_SOURCE_NAMES>, number | undefined> => ({
  [SYNC_SOURCE_NAMES.ppsIn]: config?.ppsIn?.priority,
  [SYNC_SOURCE_NAMES.gnss]: config?.gnss?.priority,
  [SYNC_SOURCE_NAMES.syncIn]: config?.syncIn?.priority,
  [SYNC_SOURCE_NAMES.osc]: config?.holdover?.priority,
  [SYNC_SOURCE_NAMES.ptp1]: config?.ptpClients?.instances?.[0]?.priority,
  [SYNC_SOURCE_NAMES.ptp2]: config?.ptpClients?.instances?.[1]?.priority,
});

export const sortByPriority = (
  { priority: prio1 = NaN }: { priority?: number },
  { priority: prio2 = NaN }: { priority?: number },
) => prio1 - prio2 || Number(isNaN(prio1)) - Number(isNaN(prio2));

export const getSyncSourceViewModels = ({
  nodeId,
  usageConfig,
  priorityConfig,
  syncSourceAvailabilities,
  syncSourcePriorities,
  syncSourceSelections,
}: {
  nodeId: string;
  usageConfig: ReturnType<typeof getSyncSourceUsageConfigMap>;
  priorityConfig: ReturnType<typeof getSyncSourcePriorityConfigMap>;
} & Partial<Required<ReturnType<typeof useNodeLinksByDirection>['data']>> &
  Partial<Required<ReturnType<typeof useTimeNodeOverviewMetrics>['data']>>) => {
  const getSyncSourceViewModelEntry = (name: ValueOf<typeof SYNC_SOURCE_NAMES>) => ({
    name,
    label: SYNC_SOURCE_LABELS[name],
    url: SYNC_SOURCE_CONFIG_URLS[name](nodeId),
    usage: usageConfig?.[name],
    available: syncSourceAvailabilities?.[name],
    priority: syncSourcePriorities?.[name] ?? priorityConfig?.[name],
    selected: syncSourceSelections?.[name],
  });

  return {
    externals: EXTERNAL_SYNC_SOURCES.map(getSyncSourceViewModelEntry).sort(sortByPriority),
    internals: [SYNC_SOURCE_NAMES.osc].map(getSyncSourceViewModelEntry).sort(sortByPriority),
    frequencies: [SYNC_SOURCE_NAMES.syncIn].map(getSyncSourceViewModelEntry).sort(sortByPriority),
  };
};

export const getSyncMode = ({
  usageConfig,
  statuses,
  incomingLinkMetrics,
  syncSourceSelections,
}: {
  incomingLinkMetrics?: LinkDirectionMeta[];
  usageConfig: ReturnType<typeof getSyncSourceUsageConfigMap>;
} & Partial<Required<ReturnType<typeof useNodeLinksByDirection>['data']>> &
  Partial<Required<ReturnType<typeof useTimeNodeOverviewMetrics>['data']>>) => {
  // MODE:  time transfer (syncing on links)
  // local sync (syncing on a local sync source)
  // network source (syncing on a local source GNSS/PPS, but not in local sync)
  // extended holdover (in HOLDOVER, but has an enabled frequency source)
  // holdover
  // free running (in holdover, has never been stable)

  const selectedLink = incomingLinkMetrics?.find(lt => lt?.selected);
  if (G.isNotNullable(selectedLink)) {
    return 'Time transfer';
  }
  const selectedSyncSourceType = Object.entries(syncSourceSelections ?? {})?.find(([, selected]) => selected)?.[0];
  if (G.isNotNullable(selectedSyncSourceType) && EXTERNAL_SYNC_SOURCES.indexOf(selectedSyncSourceType as any)) {
    return usageConfig[selectedSyncSourceType as ValueOf<typeof SYNC_SOURCE_NAMES>] === SyncSourceUsage.LocalOnly
      ? 'Local sync'
      : 'Network source';
  }
  if (statuses?.controlState === CONTROL_STATES.Holdover) {
    if (statuses?.hasNodeTimeInStable === false && statuses?.nodeTimeSinceUnstable > statuses.hiccUptime) {
      return 'Free running';
    }
    return usageConfig?.sync_in === SyncSourceUsage.Selectable ? 'Extended holdover' : 'Holdover';
  }

  return null;
};
