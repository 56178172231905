export const MANAGEMENT_IFACE = 'eth0';
export const ELECTRICAL_IFACES = ['eth-t0', 'eth-t1'];

export const DEFAULT_NAMESPACES = {
  default: '',
  management: 'management',
  access: 'access-net',
};

export const DEFAULT_NAMESPACE_LABELS = {
  [DEFAULT_NAMESPACES.default]: 'management',
  [DEFAULT_NAMESPACES.management]: 'management',
  [DEFAULT_NAMESPACES.access]: 'access',
};
