import { initContract } from '@ts-rest/core';
import {
  TTLinkEndpointSchema,
  TTLinkSpecSchema,
  TTLinkProfileSchema,
  PtpProfiles,
  G8275_2_MAX_CONNECTIONS_NO_OFFLOAD,
} from '@netinsight/crds';
import z from 'zod';
import { ServiceConfigSchema, WrappedErrorSchema } from './types';
import StatusCodes from 'http-status-codes';

export const getDefaultUnicastMaxConnectionsValue = (profile: string, maxConnectionsTotal: number) =>
  profile === PtpProfiles.G8275_1 ? 1 : maxConnectionsTotal;

export const getDefaultLegacyUnicastMaxConnectionsValue = (profile: string) =>
  profile === PtpProfiles.G8275_1 ? 1 : G8275_2_MAX_CONNECTIONS_NO_OFFLOAD;

// Sync link (2-way)

export const UnresolvedSyncLinkSchema = TTLinkSpecSchema;
export type UnresolvedSyncLink = z.infer<typeof UnresolvedSyncLinkSchema>;

export const PersistedSyncLinkSchema = UnresolvedSyncLinkSchema.extend({
  id: z.string(),
});
export type PersistedSyncLink = z.infer<typeof PersistedSyncLinkSchema>;

// Link target(s) (1-way), i.e. to answer what nodes are this node connected to

export const UnresolvedLinkTargetSchema = TTLinkEndpointSchema.extend({
  linkId: z.void(),
  index: z.void(),
});
export type UnresolvedLinkTarget = z.infer<typeof UnresolvedLinkTargetSchema>;

export const PersistedLinkTargetSchema = TTLinkEndpointSchema.extend({
  linkId: z.string(),
  name: z.string().optional(),
  autoCalibration: z.boolean(),
  profiles: z.array(TTLinkProfileSchema),
  index: z.union([z.literal(0), z.literal(1)]), // which side of the link the target is referenced in the Kubernetes CR object,
});
export type PersistedLinkTarget = z.infer<typeof PersistedLinkTargetSchema>;

export const LinkTargetSchema = z.union([UnresolvedLinkTargetSchema, PersistedLinkTargetSchema]);
export type LinkTarget = z.infer<typeof LinkTargetSchema>;

// Schema that allows adding new links (without any id)
export const UnresolvedLinkTargetListSchema = z.object({
  targets: z.array(LinkTargetSchema),
});
export type UnresolvedLinkTargetList = z.infer<typeof UnresolvedLinkTargetListSchema>;

export const PersistedLinkTargetListSchema = z.object({
  targets: z.array(PersistedLinkTargetSchema),
});
export type PersistedLinkTargetList = z.infer<typeof PersistedLinkTargetListSchema>;

const c = initContract();

export const ttLinksApi = c.router({
  listTimeTransferLinks: {
    method: 'GET',
    path: '/cluster/ttlinks',
    summary: 'Get all time transfer links',
    responses: {
      [StatusCodes.OK]: z.object({ links: z.array(PersistedSyncLinkSchema) }),
      [StatusCodes.INTERNAL_SERVER_ERROR]: WrappedErrorSchema,
    },
  },
  getTimeTransferLink: {
    method: 'GET',
    path: '/cluster/ttlinks/:id',
    summary: 'Get time transfer link',
    responses: {
      [StatusCodes.OK]: ServiceConfigSchema.extend({ config: PersistedSyncLinkSchema }),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
    },
  },
  createTimeTransferLink: {
    method: 'POST',
    path: '/cluster/ttlinks',
    summary: 'Create time transfer link',
    body: UnresolvedSyncLinkSchema,
    responses: {
      [StatusCodes.OK]: ServiceConfigSchema.extend({ config: PersistedSyncLinkSchema }),
      [StatusCodes.BAD_REQUEST]: WrappedErrorSchema,
      [StatusCodes.INTERNAL_SERVER_ERROR]: WrappedErrorSchema,
    },
  },
  updateTimeTransferLink: {
    method: 'PUT',
    path: '/cluster/ttlinks/:id',
    summary: 'Update time transfer link',
    body: UnresolvedSyncLinkSchema,
    responses: {
      [StatusCodes.OK]: ServiceConfigSchema.extend({ config: PersistedSyncLinkSchema }),
      [StatusCodes.BAD_REQUEST]: WrappedErrorSchema,
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
    },
  },
  deleteTimeTransferLink: {
    method: 'DELETE',
    path: '/cluster/ttlinks/:id',
    summary: 'Delete time transfer link',
    body: null,
    responses: {
      [StatusCodes.OK]: z.null(),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
    },
  },

  getNodeLinkTargets: {
    method: 'GET',
    path: '/nodes/:nodeId/ttlinks/config',
    summary: 'Get time transfer link targets',
    responses: {
      [StatusCodes.OK]: ServiceConfigSchema.extend({ config: PersistedLinkTargetListSchema }),
      [StatusCodes.NOT_FOUND]: WrappedErrorSchema,
    },
  },
});
