import React from 'react';
import { UserSettingsProfileCard, UserSettingsAppearanceCard } from '@backstage/plugin-user-settings';
import { Grid } from '@material-ui/core';

export const GeneralSettingsTab = () => {
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12} md={6}>
        <UserSettingsProfileCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <UserSettingsAppearanceCard />
      </Grid>
    </Grid>
  );
};
