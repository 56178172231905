import React from 'react';
import { Content } from '@backstage/core-components';
import { Grid } from '@material-ui/core';
import { GlobalSSHConfigCard } from '../GlobalSSHConfigCard';

export const TimeNodeGlobalSettings = () => {
  return (
    <Content>
      <Grid item xs={12} md={6}>
        <GlobalSSHConfigCard />
      </Grid>
    </Content>
  );
};
